<template>
    <div class="row">
        <div class="col-md-12 col-lg-8">
            <h3><div class="point"></div> Preisgelder</h3>

            <div className="box">
                <table border="0" width="25%" cellspacing="1" cellpadding="1">
                    <tbody>
                    <tr>
                        <td>Turniersieger</td>
                        <td>3.000 €</td>
                    </tr>
                    <tr>
                        <td>2. Platz</td>
                        <td>1.500 €</td>
                    </tr>
                    <tr>
                        <td>3. Platz</td>
                        <td>1.000 €</td>
                    </tr>
                    <tr>
                        <td>4. Platz</td>
                        <td>750 €</td>
                    </tr>
                    <tr>
                        <td>5. Platz</td>
                        <td>475 €</td>
                    </tr>
                    <tr>
                        <td>6. Platz</td>
                        <td>425 €</td>
                    </tr>
                    <tr>
                        <td>7. Platz</td>
                        <td>375 €</td>
                    </tr>
                    <tr>
                        <td>8. Platz</td>
                        <td>325 €</td>
                    </tr>
                    <tr>
                        <td>Plätze 9-12 je.</td>
                        <td>275 €</td>
                    </tr>
                    <tr>
                        <td>Plätze 13-16 je.</td>
                        <td>225 €</td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <p><strong>Flüchter Preis</strong><br />
                    Der von der Jury zum besten Spieler des Turniers gewählte Spieler erhält ein Preisgeld von 500 €, welches teilweise einem wohltätigen Zweck zugeführt wird. Weitere Informationen finden Sie <router-link :to="{name: 'fluechter@view'}"><strong>HIER</strong></router-link>.</p>
                <p><strong>Torwart Preis</strong><br />
                    Der von der Jury zum besten Torwart des Turniers gewählte Spieler erhält ein Preisgeld von 100 €.&nbsp;Weitere Informationen finden Sie <router-link :to="{name: 'masters@goalkeeperprice'}"><strong>HIER</strong></router-link>.</p>
                <p><strong>Torschützenkönig</strong><br />
                    Der Spieler, der die meisten Tore aus dem laufenden Spiel erzielt, erhält ein Preisgeld von 100 €.&nbsp;Weitere Informationen finden Sie <router-link :to="{name: 'masters@topscorer'}"><strong>HIER</strong></router-link>.</p>

            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "WinPrices",

    created()
    {
        useHead({
            title: "Preisgelder - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>
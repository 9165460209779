<template>
    <div class="row">
        <div class="col-md-12 col-lg-12">
            <h3><div class="point"></div>
                <div class="eickelmann_point"></div>- Torwart Preis</h3>

            <div className="box">
                <div className="main-timeline">
                    <div class="timeline" :class="player.year % 2 === 1 ? 'left' : 'right'" v-for="player in goalkeepers" v-bind:key="player.year">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>{{ player.year}}</h3>
                                <div className="winnerImg"
                                     :style="{'background-image': 'url(/img/torwart/' + (player.img) + ')'}">
                                    <img :src="'/img/torwart/' + (player.img)" />
                                </div>

                                <p className="mt-4 mb-0" style="text-align: center">{{ player.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
    name: "GoalkeeperPrice",
    data()
    {
        return {
            goalkeepers: []
        }
    },

    created()
    {
        useHead({
            title: "KWK Küchen - " + this.tournamentName
        });

        this.goalkeepers = [
            {
                year: "2023",
                img: "TW_2023.jpg",
                text: "Eric Frewel (FC Arpe/Wormbach)"
            },
            {
                year: "2022",
                img: "TW_2022.jpg",
                text: "Sascha Wachsmann (VfB Marsberg)"
            },
            {
                year: "2019",
                img: "TW_2019.jpg",
                text: "Burak Ege (FC Fatih Türkgücü Meschede)"
            },
            {
                year: "2018",
                img: "TW_2018.jpg",
                text: "Torben Dicke (BC Eslohe)"
            },
            {
                year: "2017",
                img: "TW_2017.png",
                text: "Anton Pavic (SV Brilon)"
            },
            {
                year: "2016",
                img: "TW_2016.jpg",
                text: "Jannik Erlmann (TuRa Freienohl)"
            },
            {
                year: "2015",
                img: "TW_2015.png",
                text: "Marvin Pick (HSK-A-Junioren-Auswahl)"
            },
            {
                year: "2014",
                img: "TW_2014.png",
                text: "Timo Hildebrand (TuS Oeventrop)"
            },
            {
                year: "2013",
                img: "TW_2013.png",
                text: "Turkan Öz (FC Fatih Türkgücü Meschede)"
            },
            {
                year: "2012",
                img: "TW_2012.png",
                text: "Ralf Westig (HSK-Ü-32-Auswahl)"
            },
            {
                year: "2011",
                img: "TW_2011.png",
                text: "Ralf Westig (HSK-Ü-32-Auswahl)"
            },
            {
                year: "2010",
                img: "TW_2010.png",
                text: "Jannik Erlmann (TuRa Freienohl)"
            },
            {
                year: "2009",
                img: "TW_2009.png",
                text: "Ralf Westig (HSK-Ü-32-Auswahl)"
            },
        ];
    }
}
</script>

<style scoped>

</style>
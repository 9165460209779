<template>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <h3><div class="point"></div> Eintrittspreise & Anstoßzeiten</h3>
      <div class="box">
        <h5>Austragungsort</h5>
        <p>Mescheder Dünnefeldsporthalle am Berufskolleg Meschede des Hochsauerlandkreises (Dünnefeldweg 5, 59872 Meschede)</p>

        <h5 style="margin-top:30px;">Anstoßzeiten der einzelnen Spieltage</h5>
        <table class="times_prices">
          <tbody>
          <tr>
            <td style="text-align: center;"><strong>Datum</strong></td>
            <td style="text-align: center;"><strong>ALS Champion Masters</strong></td>
              <td style="text-align: center;"><strong>Sparkassen Cup</strong></td>
              <td style="text-align: center;"><strong>Einlass ab</strong></td>
          </tr>
          <tr>
            <td style="text-align: center;">27. Dezember</td>
            <td style="text-align: center;">16:30 Uhr</td>
              <td style="text-align: center;">15:00 Uhr</td>
              <td style="text-align: center;">14:30 Uhr</td>
          </tr>
          <tr>
            <td style="text-align: center;">28. Dezember</td>
            <td style="text-align: center;">16:30 Uhr</td>
            <td style="text-align: center;">15:00 Uhr</td>
              <td style="text-align: center;">14:30 Uhr</td>
          </tr>
          <tr>
            <td style="text-align: center;">29. Dezember</td>
            <td style="text-align: center;">14:00 Uhr</td>
            <td style="text-align: center;">13:30 Uhr</td>
              <td style="text-align: center;">12:30 Uhr</td>
          </tr>
          </tbody>
        </table>

        <h5 style="margin-top:30px;">Eintrittspreise {{ this.$pageName }}</h5>
        <table class="times_prices">
          <tbody>
          <tr>
            <td style="text-align: left;padding-left:10px">Tageskarte Erwachsene</td>
            <td style="text-align: center;">8,00 €</td>
          </tr>
          <tr>
            <td style="text-align: left;padding-left:10px">Tageskarte Jugendliche (ab 12 Jahre) / Rentner / Studenten</td>
            <td style="text-align: center;">4,00 €</td>
          </tr>
          <tr>
            <td style="text-align: left;padding-left:10px">Behinderte (mit Begleitperson) / Schiedsrichter</td>
            <td style="text-align: center;">Eintritt frei</td>
          </tr>
          </tbody>
        </table>
        <p style="font-size:12px;margin-top:10px;"><em>Änderungen sind möglich!</em></p>
      </div>
    </div>
  </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "EntranceFees",

    created()
    {
        useHead({
            title: "Eintrittspreise & Anstoßzeiten - " + this.tournamentName
        });
    }
}
</script>
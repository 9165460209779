import {RouteRecordRaw} from "vue-router";

import GalleryOverview from '../views/Gallery/Overview.vue';
import GalleryImages from "@/views/Gallery/Images.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/galerie",
        name: "galerie@overview",
        component: GalleryOverview
    },
    {
        path: "/galerie/:id/:title",
        name: "galerie@images",
        component: GalleryImages,
    },
];

export default routes;
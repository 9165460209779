<template>
  <div class="row">
    <div class="col-sm-12 col-md-9">
      <h3><div class="point"></div> Vorverkauf</h3>
      <div class="box">
        Liebe Fußballfans,<br>
        <br>
        der SC Olpe muss ab diesem Jahr aus sicherheits-, und versicherungstechnischen Gründen nach einem Gespräch mit den zuständigen Behörden in Zukunft die Zuschauerkapazität begrenzen.
        <br><br>
        Das bedeutet für uns ein Umdenken im Ticketverkauf.<br>
        <br>
        Das wichtigste ist dabei, dass alle Fußallfans die gleiche Chance haben, eine Eintrittskarte zu erwerben.<br>
        <br>
        Deshalb hat die Turnierleitung folgende Änderungen beschlossen:<br>
        <br>
        Ab sofort werden keine Dauerkarten mehr verkauft, weil Dauerkartenbesitzer, wenn sie das Turnier nicht besuchen, Plätze blockieren.<br>
        Der VV wird abgeschafft, weil für ihn das Gleiche gilt, wie bei den Dauerkarten.<br>
        Es gibt also nur noch Tageskarten an den Tageskassen zu den bekannten, unveränderten Preisen.<br>
        Sobald alle Tageskarten verkauft sind, wird der Einlass geschlossen.<br>
        <br>
        Wir hoffen auf euer Verständnis für diese Maßnahmen, um euch auch in den nächsten Jahren viel Freude beim Hallenfußball in gemütlicher Atmosphäre bieten zu können.
        <br><br>
        Die Turnierleitung

      </div>
    </div>
  </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "PreSale",

    created()
    {
        useHead({
            title: "Vorverkauf - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>
<template>
    <div class="row">
        <div class="col-sm-12">
            <h3><div class="point"></div><div class="als_point"></div> - Hall of Masters Gewinner</h3>

            <div className="box">
                <div className="main-timeline">
                    <div class="timeline" :class="player.id % 2 === 0 ? 'left' : 'right'" v-for="player in hallOfMasters" v-bind:key="player.id">
                        <div className="card">
                            <div className="card-body p-4">
                                <h3>{{ player.name }} ({{ player.title }} Titel)</h3>
                                <div className="winnerImg"
                                     :style="{'background-image': 'url(/img/hall_of_masters/' + (player.img) + ')'}">
                                    <img :src="'/img/hall_of_masters/' + (player.img)" />
                                </div>

                                <p className="mt-3 mb-0" style="text-align: center" v-html="player.wins"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
    name: "WinnersHallOfFame",
    created()
    {
        useHead({
            title: "ALS Hall of Masters Gewinner - " + this.tournamentName
        });

        this.hallOfMasters = [
            {
                id: "4",
                name: "Bilal Akgüvercin",
                title: 7,
                img: "004.jpg",
                wins: "<b>Flüchterpreis</b>: 2016, 2017, 2018, 2022<br><b>Torschützenkönig</b>: 2013, 2019, 2022"
            },
            {
                id: "3",
                name: "Burak Yavuz",
                title: 4,
                img: "003.jpg",
                wins: "<b>Flüchterpreis</b>: 2011, 2014, 2015<br><b>Torschützenkönig</b>: 2011"
            },
            {
                id: "2",
                name: "Daniel Berlinski",
                title: 4,
                img: "002.jpg",
                wins: "<b>Flüchterpreis</b>: 2008, 2012, 2013<br><b>Torschützenkönig</b>: 2008"
            },
            {
                id: "1",
                name: "Ralf Westig",
                title: 3,
                img: "001.jpg",
                wins: "<b>Torwartpreis</b>: 2011, 2012, 2009"
            },
        ];
    },

    data()
    {
        return {
            hallOfMasters: []
        }
    },
}
</script>

<style scoped>

</style>
<template>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <h3><div class="point"></div><div class="als_point"></div> - Hall of Masters</h3>

            <div class="box" style="width:100%;">
                Der SC Olpe gründet zum 25. Masters die <img style="margin-top:-4px;" src="/img/sponsoren/als_ohne_text.png" height="20" width="53" /><b> - Hall of Masters</b>.<br>
                <br>
                In diese fiktive Ruhmeshalle wollen wir alle Fußballer aufnehmen, die sich beim <img style="margin-top:-4px;" src="/img/sponsoren/als_ohne_text.png" height="20" width="53" /> - Champion Masters
                ganz besonders hervorgetan haben, weil sie mit außerordentlichen Leistungen für Höhepunkte bei diesem Turnier sorgten oder sorgen werden.<br>
                <br>
                <u>Nach welchen Kriterien wird über die Aufnahme entschieden?</u><br>
                Und das ist das Besondere. Jeder Spieler entscheidet ganz alleine, ob er in die <img style="margin-top:-4px;" src="/img/sponsoren/als_ohne_text.png" height="20" width="53" /><b> - Hall of Masters</b> aufgenommen wird oder nicht.<br>
                <br>
                <u>Was muss er für die Aufnahme leisten?</u><br>
                Beim Masters kann ein Spieler folgende Titel gewinnen:<br>
                • <img style="margin-top:-4px;" src="/img/sponsoren/autocenter-meschede.jpg" height="20" width="120" /> Flüchter Preis<br>
                • <img style="margin-top:-4px;" src="/img/sponsoren/kwk-kuechen.jpg" height="25" width="115" /> Torwart Preis<br>
                • <img style="margin-top:-4px;" src="/img/sponsoren/landhotel-huetter.png" height="30" width="112" /> Torschützenkönig<br>
                <br>
                Jeder Spieler, der es schafft, mindestens drei dieser Titel zu gewinnen, wird in die <img style="margin-top:-4px;" src="/img/sponsoren/als_ohne_text.png" height="20" width="53" /><b> - Hall of Masters</b> aufgenommen.<br>
                <br>
                Seitdem ersten Turnier 1996 haben es 4 Spieler in die <img style="margin-top:-4px;" src="/img/sponsoren/als_ohne_text.png" height="20" width="53" /><b> - Hall of Masters</b> geschafft. Diese werden wir am Finaltag während des Turniers gegen 19:30 Uhr offiziell in die Ruhmeshalle aufnehmen.
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
    name: "HallOfFame",

    created()
    {
        useHead({
            title: "ALS Hall of Masters - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>
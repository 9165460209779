import {RouteRecordRaw} from "vue-router";

import MastersFluechterOverview from '../views/Fluechterprice/Overview.vue';
import MastersFluechterPrice from '../views/Fluechterprice/FluechterPrice.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/fluechterpreis",
        name: "fluechter@view",
        component: MastersFluechterOverview
    },
    {
        path: "/fluechterpreis/gewinner",
        name: "fluechter@gewinner",
        component: MastersFluechterPrice
    },
];

export default routes;
<template>
    <div class="row">
        <div class="col-md-12 col-lg-8">
            <h3>
                <div class="point"></div> Kontakt
            </h3>

            <div class="box">
                <b>Anschrift</b>:<br>
                SC 1911 Olpe e.V.<br>
                Postfach 3223<br>
                59861 Meschede<br><br><br>

                <b>Anmerkungen bzw. Fragen an die Organisatoren</b><br>
                <a href="mailto:masters@sc-olpe.de" target="_blank">masters@sc-olpe.de</a><br><br><br>

                <b>Anmerkungen bzw. Fragen zur Homepage</b><br>
                <a href="mailto:fabian@gerke.dev" target="_blank">fabian@gerke.dev</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactView"
}
</script>

<style scoped>

</style>
<template>
    <div id="fb-root"></div>
    <div class="row">
        <div class="col-12 col-md-8 col-lg-6 order-lg-1 order-sm-1 order-md-2">
            <h3><div class="point"></div> Neuigkeiten</h3>

            <div style="width:100%;" v-if="newsArr === null">
                <ContentLoader height="350" width="100%" style="background:rgba(0,0,0,0.1)">
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="250" />
                    <rect x="2%" y="270" rx="3" ry="3" width="96%" height="20" />

                    <rect x="2%" y="280" rx="3" ry="3" width="96%" height="10" />
                    <rect x="2%" y="295" rx="3" ry="3" width="85%" height="10" />
                    <rect x="2%" y="310" rx="3" ry="3" width="80%" height="10" />
                    <rect x="2%" y="325" rx="3" ry="3" width="55%" height="10" />
                </ContentLoader>
                <ContentLoader height="350" width="100%" style="background:rgba(0,0,0,0.1);margin-top:30px;">
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="250" />
                    <rect x="2%" y="270" rx="3" ry="3" width="96%" height="20" />

                    <rect x="2%" y="280" rx="3" ry="3" width="96%" height="10" />
                    <rect x="2%" y="295" rx="3" ry="3" width="85%" height="10" />
                    <rect x="2%" y="310" rx="3" ry="3" width="80%" height="10" />
                    <rect x="2%" y="325" rx="3" ry="3" width="55%" height="10" />
                </ContentLoader>
                <ContentLoader height="350" width="100%" style="background:rgba(0,0,0,0.1);margin-top:30px;">
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="250" />
                    <rect x="2%" y="270" rx="3" ry="3" width="96%" height="20" />

                    <rect x="2%" y="280" rx="3" ry="3" width="96%" height="10" />
                    <rect x="2%" y="295" rx="3" ry="3" width="85%" height="10" />
                    <rect x="2%" y="310" rx="3" ry="3" width="80%" height="10" />
                    <rect x="2%" y="325" rx="3" ry="3" width="55%" height="10" />
                </ContentLoader>
            </div>
            <div id="newsbereich" v-if="newsArr !== null && newsArr.length === 0">
                Derzeit gibt es keine Neuigkeiten rund um das ChampionMasters.
            </div>
            <div id="newsbereich" v-if="newsArr !== null && newsArr.length > 0">
                <router-link :to="{name: 'news', params: {id: news.id, title: news.headline_url}}" class="news" v-for="news in newsArr" v-bind:key="news.id" v-on:click="moveUp">
                    <div class="image" :style="{'background-image': 'url(https://kleinolpe.de/data/news/' + news.image + ')'}" style="background-position:top;"></div>

                    <div class="title">{{ news.headline }}</div>
                    <div class="description" style="color:black;">{{ news.description }}...</div>
                </router-link>
            </div>
        </div>

        <div class="col-12 col-md-8 col-lg-3 order-lg-1 order-sm-1 order-md-2">
            <h3><div class="point"></div> Programmheft</h3>
            <a href="programmheft/ALS_ChampionMasters_26_Web.pdf" target="_blank"><div id="programmheft"></div></a>

            <div style="height:auto;margin-top:20px;background:rgba(0, 0, 0, 0.1);width:360px;">
                <a href="https://www.pce-instruments.com/deutsch/pce-deutschland-gmbh-pruefgeraete-anbieter_1.htm" target="_blank"><div class="sponsor pce" style="margin: 0;"></div></a>
                <center style="margin-top:10px;margin-bottom: 10px;font-weight:bold;">präsentiert</center>
                <div style="display: flex; justify-content: center; align-items: center;width:100%;">
                    <div class="fb-page" data-href="https://www.facebook.com/ChampionMasters" style="margin-left:10px;width:calc(100% + 20px);" data-tabs="" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/ChampionMasters" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ChampionMasters">Champion Masters Meschede</a></blockquote></div>
                </div>
            </div>

            <h3 style="margin-top: 70px;"><div class="point"></div> Sponsoren</h3>
            <a href="https://www.als-arnsberg.de/" target="_blank"><div class="sponsor als"></div></a>
            <a href="https://www.autocenter-meschede.de/" target="_blank"><div class="sponsor autocenter_meschede"></div></a>
            <a href="https://www.hotel-huetter.de/" target="_blank"><div class="sponsor landhotel_huetter"></div></a>
            <a href="https://www.kwk-kuechen.de/" target="_blank"><div class="sponsor kwk_kuechen"></div></a>
            <a href="https://www.sparkasse-mitten-im-sauerland.de/de/home.html" target="_blank"><div class="sponsor sparkasse"></div></a>
            <a href="https://veltins.de" target="_blank"><div class="sponsor veltins"></div></a>
        </div>
    </div>
</template>

<style>
#newsbereich a{
    text-decoration: none;
}
</style>

<script>
import { ContentLoader } from 'vue-content-loader';
import {useHead} from "@vueuse/head";
import axios from 'axios';

export default {
    name: "HomeView",
    components: {
        ContentLoader
    },
    data(){
        return{
            newsArr: null
        }
    },
    created() {
        useHead({
            title: "Startseite - " + this.tournamentName
        });

      axios.get('https://kleinolpe.de/api/championmasters/newest_news')
          .then(response => (this.newsArr = response.data))
          .catch(error => console.log(error))
    },

    methods: {
        moveUp()
        {
            setTimeout(() => {
                window.scrollTo(0,0);
            }, 125);
        },
    }
}
</script>
<template>
    <div class="row">
        <div class="col-sm-12 col-md-8">
            <h3><div class="point"></div> Geschichte des Champion Masters</h3>

            <div className="box">
                Die Geburtsstunde des Champion Masters geht zurück in das Jahr 1995. Im Herbst dieses Jahres saßen nach einem Meisterschaftsspiel unserer 1. Mannschaft einige Spieler mit dem damaligen Vorsitzenden Benno Bohne in unserem Vereinslokal Landgasthof Hütter zusammen. Wir unterhielten uns bei frischem VELTINS unter anderem darüber, ob es Sinn mache, bei der zu dieser Zeit großen Anzahl von Hallenturnieren ein eigenes Turnier zu veranstalten.
                <br><br>
                Einig war sich die Runde darin, dass es ein besonderes Turnier sein müsse, ein Turnier, das es in seiner Form bis dahin noch nicht gab.
                <br><br>
                Aber was?
                <br><br>
                Doch plötzlich kam der geniale Gedanke. Carsten Geldmacher und Eric Wachholz, beide seinerzeit Spieler der 1. Mannschaft, machten den Vorschlag, eine HSK-Hallenmeisterschaft auszuspielen, an der alle Städte und Gemeinden des Hochsauerlandkreises beteiligt sind. Alle waren sich einig, dass das die Basis für ein außergewöhnliches und in seiner Art einmaliges Hallenturnier ist.
                <br><br>
                Der Vorsitzende Benno Bohne war der Meinung, dass an einer HSK-Meisterschaft auch die höherklassigen Mannschaften teilnehmen sollten. Er zweifelte jedoch an dem Interesse dieser Vereine. Es gab schließlich eine Vielzahl von Turnieren, unter anderem den damaligen WFG-Cup, der sich über mehrere Wochen erstreckte, etabliert war und mit gutem Preisgeld lockte. Nicht zu vergessen, das zeitgleich ins Leben gerufene VELTINS-Masters des SSV Meschede und einige Stadtmeisterschaften, die Kultstatus haben und auch um die Jahreswende ausgespielt werden.
                <br><br>
                „Die TOP-Teams nehmen an unserem Turnier nur teil, wenn wir gutes Preisgeld bieten“ so der Vorsitzende. Aber woher das Geld nehmen?
                <br><br>
                Im Januar war Benno Bohne mit Wilfried Pfitzner und Klaus Geuecke von der Firma PFIFF auf einer Geschäftsreise in Indien und Taiwan. Während einer mehrstündigen Autofahrt durch das Nainital im Himalayagebirge erzählte Benno Bohne von der Turnieridee. Beide waren von der Idee begeistert und versprachen, an der Umsetzung mitzuarbeiten. Die drei waren sich einig, dass bei einem Gesamtpreisgeld von 20.000,- DM auch die „großen“ Vereine des HSK nicht „widerstehen“ können. In den nächsten Wochen wurden von den drei „Indern“ viele Sponsorengespräche geführt.
                <br><br>
                Das 1. Champion Masters am 28./29. Dezember 1996 wurde von folgenden Firmen unterstützt:
                <br><br>
                Brauerei VELTINS, Sparkasse Meschede, Druckerei F.W. Becker, Rölex Design, Fachsport Sundern, PFIFF, Teppichland Meschede, Wittgensteiner Kliniken, Radio Sauerland, Landhotel Hütter, Möbelhaus Schröer, Kielgast Überdachungstechnik, Bauunternehmung Sellmann und LVM-Versicherungen Leifert.
                <br><br>
                Seit 1998 hat das Turnier einen festen Platz im Turnierkalender. Es findet immer vom 28. – 30. Dezember in der Dünnefeldhalle Meschede statt.
                <br><br>
                Die ersten vier Turniere liefen unter dem Namen „Sportiv“ – Champion Masters.
                <br><br>
                Seit 2003 präsentiert sich das Turnier mit dem Hauptsponsor PFIFF  als „PFIFF Sport Champion Masters“.
                <br><br>
                In dem Jahr nahm auch zum ersten Mal eine U18- Juniorenauswahl des Hochsauerlandkreises mit einer Wildcard an dem Turnier teil und ist seitdem fester Bestandteil des Turniers. Die Mannschaft wurde bis 2011 von dem Fußballlehrer Sascha Eickel zusammengestellt und betreut. Wolfgang Kersch unterstützte ihn einige Jahre als Co-Trainer.
                <br><br>
                Eine weitere Neuerung im Jahre 2003 war die erstmalige Verleihung des „Christian Flüchter – Gedächtnispreises“ an den besten Spieler des Turniers. Der Preis soll an den viel zu früh verstorbenen Fußballer aus Olpe erinnern.
                <br><br>
                Im Jahr 2005 fand in Verbindung mit dem PFIFF Sport Champion Masters erstmals der Sparkassen Cup für A-Junioren statt. An diesem Wettbewerb nehmen alle A-Juniorenteams der Stadt Meschede und der Gemeinde Eslohe teil. Im Jahr 2010 hat die Sparkasse Meschede/ Eslohe für dieses Turnier ebenfalls einen Wanderpokal zur Verfügung gestellt.
                <br><br>
                Im Jahr 2006 wurde zum ersten Mal der Torschützenkönig des Turniers offiziell ausgezeichnet.
                <br><br>
                Seit 2009 erhält auch als Gegenpart der U18-Juniorenauswahl eine Ü32-Seniorenauswahl des Hochsauerlandkreises eine Wildcard für das Turnier.
                <br><br>
                Seit dem Jahr 2009 wird der beste Torwart des Turniers offiziell ausgezeichnet.
                <br><br>
                2011 wurde der Wanderpokal, der natürlich in die Jahre gekommen war, in neuem Glanz präsentiert. Die Firma Missal aus Sundern, wo die Trophäe auch gekauft wurde, hat den Pokal „runderneuert“.
                <br><br>
                Beim 20-ten Masters 2015 haben wir zum ersten Mal auf zwei großen Leinwänden gegenüber der Zuschauertribüne unseren Sponsoren die Möglichkeit gegeben, Videowerbung zu schalten.
                <br><br>
                Eine weitere Neuerung gab es im Jahr 2016:
                <br><br>
                Das Finale wird erstmals über 2 x 7 Minuten ausgespielt.
                <br><br>
                2018 wurde der Turniername von PFIFF Sport Champion Masters in PFIFF Champion Masters abgeändert.
                <br><br>
                Nach 15 Turnierteilnahmen seit 2003 spielte im Jahr 2018 erstmals keine U18-Juniorenauswahl beim Masters.
                <br><br>
                Im Jahr 2019 konnten erstmals alle Zuschauer über eine app. den besten Spieler und den besten Torwart des Turniers wählen.
                <br><br>
                Die 25.-te Auflage des Masters fiel in den Jahren 2020 und 2021 der Corona-Pandemie zum Opfer.<br><br>
                2022 war es dann wieder soweit. Zur 25.-ten Auflage hat der SC Olpe den in die Jahre gekommenen Wanderpokal ausgetauscht.<br>
                Außerdem wurde ein Erkennungslogo für das Turnier erstmals verwendet. Dazu erhielt auch das Programmheft ein entsprechendes, angepasstes, neues Layout.<br>
                Die wichtigste Neuerung aber war die Präsentation des neuen Turniernamens.  Nach fast 20 Jahren übergab die Firma PFIFF den Stab an die Firma A.L.S. Land-, und Seespedition aus Hüsten, die schon seit 2014 als Sponsor dabei ist.
                <br><br>
                Außerdem wurde die „HALL OF MASTERS“ in´s Leben gerufen. In diese virtuelle Ruhmeshalle werden alle Spieler aufgenommen, die sich mit ganz besonderen sportlichen Leistungen um das Masters verdient gemacht haben.<br>
                Die ersten vier Spieler, die das seit Turnierbeginn 1996 geschafft haben, sind:<br>
                - Ralf Westig<br>
                - Daniel Berlinski<br>
                - Burak Yavus<br>
                - Bilal Akgüvercin
                <br><br>
                Beim 26. ALS Champion Masters wurde erstmals mit einem von uns unabhängigen Livestream (Westfalen Post) vom gesamten Finaltag berichtet.
                <br><br>
                Das Turnier hat sich aus den Anfängen 1996 bis heute zu einem echten Highlight des Hallenfußballs entwickelt. Die besten Belege dafür sind die an allen Tagen ausverkaufte Halle in den letzten Jahren, die enorm umfangreiche Berichterstattung aller Medien vor, während und nach dem Turnier und nicht zuletzt das immer größer werdende Interesse von Sponsoren.
                <br><br>
                An dieser Stelle wollen wir nicht versäumen, uns bei den vielen Helferinnen und Helfern in all den Jahren zu bedanken, ohne die dieses Turnier nicht stattfinden könnte.
                <br>

                <small><i>Stand November 2023</i></small>
            </div>
        </div>
    </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "HistoryView",

    created()
    {
        useHead({
            title: "Geschichte - " + this.tournamentName
        });
    }
}
</script>

<style scoped>

</style>
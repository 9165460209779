import {RouteRecordRaw} from "vue-router";

import SparkasseWinner from "../views/Sparkasse/Winner.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/sparkassencup/sieger",
        name: "sparkasse@winner",
        component: SparkasseWinner,
    },
];

export default routes;
import {RouteRecordRaw} from "vue-router";

import MastersEntranceFeeds from '../views/Masters/EntranceFees.vue';
import MastersTournamentWinner from '../views/Masters/TournamentWinner.vue';
import MastersTopScorer from '../views/Masters/TopScorer.vue';
import MastersGoalkeeperPrice from '../views/Masters/GoalkeeperPrice.vue';
import MastersWinPrices from '../views/Masters/WinPrices.vue';
import MastersHistory from '../views/Masters/History.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/masters/eintrittpreise",
        name: "masters@entrancefeeds",
        component: MastersEntranceFeeds,
    },
    {
        path: "/masters/turniersieger",
        name: "masters@tournamentwinner",
        component: MastersTournamentWinner
    },
    {
        path: "/masters/torschuetzenkoenige",
        name: "masters@topscorer",
        component: MastersTopScorer
    },
    {
        path: "/masters/torwartpreis",
        name: "masters@goalkeeperprice",
        component: MastersGoalkeeperPrice
    },
    {
        path: "/masters/preise",
        name: "masters@prices",
        component: MastersWinPrices
    },
    {
        path: "/masters/historie",
        name: "masters@history",
        component: MastersHistory
    }
];

export default routes;
<template>
    <div class="row">
        <div class="col-md-12 col-lg-9">
            <h3>
                <div class="point"></div> Bildergalerien
            </h3>
            <div class="box" style="background: white;">
                <div class="gallerie" v-for="gallery in galleries" v-bind:key="gallery.id">
                    <router-link :to="{name: 'galerie@images', params: {id: gallery.id, title: gallery.url}}">{{ gallery.name }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {useHead} from "@vueuse/head";

export default {
  name: "GalleryOverview",
    data()
    {
        return {
            galleries: []
        }
    },

    created()
    {
        useHead({
            title: "Bildergalerien - " + this.tournamentName,
        });

        axios.get('https://kleinolpe.de/api/championmasters/galleries')
            .then(response => {
                console.log(response.data);
                this.galleries = response.data;
            })
            .catch(error => console.log(error))
    }
}
</script>
<template>
    <div id="content">
        <div class="row" v-if="!loadedImages">
            <div class="col-12">
                <h2 style="color:white;text-align:center">Bildergalerie wird geladen...</h2>
            </div>
        </div>

        <div class="row" v-if="loadedImages">
            <div class="col-sm-12" style="margin-bottom:30px;">
                <div class="box">
                    <div id="gallery">
                        <lightgallery
                                :settings="{ speed: 500, plugins: plugins }"
                        >
                            <a v-for="(thumb, thumbIndex) in images"
                               :key="thumbIndex"
                               :href="thumb">
                                <img alt="" :src="thumb" />
                            </a>
                        </lightgallery>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-zoom.css';

#gallery .lightgallery-vue{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    row-gap: 10px;
    column-gap: 10px;
}
#gallery .lightgallery-vue a{
    width: 300px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
#gallery a img{
    width: 100%;
    height: auto;
}
</style>

<script>
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

import axios from "axios";

export default {
    name: "ImagesView",
    components: {
        Lightgallery,
    },

    data(){
        return {
            loadedImages: false,
            gallery: null,
            images: [],
            index: null,
            plugins: [lgThumbnail, lgZoom],
        }
    },
    created() {
        this.LoadGallery();
    },
    methods: {
        LoadGallery()
        {
            axios
                .get(`https://kleinolpe.de/api/championmasters/galleries/${this.$route.params.id}`)
                .then(
                    (response) => {
                        this.gallery = response.data;
                        this.images = [];

                        for(let i = 0; i < this.gallery.images.length; i++)
                        {
                            this.images.push("https://kleinolpe.de/data/gallery/" + this.gallery.path + "/" + this.gallery.images[i].path);
                        }

                        this.loadedImages = true;
                    },
                    () => {
                        this.$router.push({name: "gallery"});
                    }
                );
        }
    }
}
</script>

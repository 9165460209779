import {RouteRecordRaw} from "vue-router";

import MastersHallOfFame from '../views/HallOfFame/HallOfFame.vue';
import HallOfFameWinner from '../views/HallOfFame/Winners.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/hall_of_fame/info",
        name: "hallOfFame@info",
        component: MastersHallOfFame
    },
    {
        path: "/hall_of_fame/gewinner",
        name: "hallOfFame@winner",
        component: HallOfFameWinner
    },
];

export default routes;